import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "app-dialog-box"
};
var _hoisted_2 = {
  class: "app-dialog__title"
};
var _hoisted_3 = {
  class: "app-dialog__content"
};
var _hoisted_4 = {
  ref: "completeButtonRef"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DialogTitle = _resolveComponent("DialogTitle");

  var _component_DialogDescription = _resolveComponent("DialogDescription");

  var _component_Dialog = _resolveComponent("Dialog");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" Dialog "), _createVNode(_component_Dialog, {
    as: "div",
    initialFocus: _ctx.completeButtonRef,
    open: _ctx.isShow,
    onClose: _ctx.onClose,
    class: "app-dialog"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_DialogTitle, {
        class: "app-dialog__header"
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1
          /* TEXT */
          )];
        }),
        _: 1
        /* STABLE */

      }), _createVNode(_component_DialogDescription, null, {
        default: _withCtx(function () {
          return [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.content), 1
          /* TEXT */
          )];
        }),
        _: 1
        /* STABLE */

      }), _createElementVNode("button", _hoisted_4, "OK", 512
      /* NEED_PATCH */
      )])];
    }),
    _: 1
    /* STABLE */

  }, 8
  /* PROPS */
  , ["initialFocus", "open", "onClose"])], 2112
  /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
  );
}