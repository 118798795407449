import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils'; // Định nghĩa data type của component [props] bằng Typescript

var UserAvatarComponentProps = function UserAvatarComponentProps() {
  _classCallCheck(this, UserAvatarComponentProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var UserAvatarComponentData = function UserAvatarComponentData() {
  _classCallCheck(this, UserAvatarComponentData);
};

export default defineComponent({
  name: 'UserAvatarComponent',
  props: {
    currentUser: {
      type: Object,
      default: null
    },
    imgSrc: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      currentUser: props.currentUser,
      imgSrc: props.imgSrc
    });
    watch(function () {
      return props.currentUser;
    }, function (newValue, oldValue) {
      return data.currentUser = newValue;
    });
    watch(function () {
      return props.imgSrc;
    }, function (newValue, oldValue) {
      return data.imgSrc = newValue;
    });
    return {
      // Component reactive data
      data: data
    };
  }
});