import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import PlaceholderApp from '@/components/ui/icon/PlaceholderApp.vue'; // Định nghĩa data type của component [props] bằng Typescript

var HomeAppDialogProps = function HomeAppDialogProps() {
  _classCallCheck(this, HomeAppDialogProps);
};

export default defineComponent({
  name: 'AppHomeDialog',
  components: {
    PlaceholderApp: PlaceholderApp
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['update:visible', 'goToApp'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      visible: props.visible
    });
    watch(function () {
      return props.visible;
    }, function (newValue, oldValue) {
      return data.visible = newValue;
    });

    var goToApp = function goToApp() {
      emit('goToApp');
    };

    return {
      // Component reactive data
      data: data,
      // Functions
      goToApp: goToApp
    };
  }
});