import { defineComponent } from 'vue';
import { useInit } from '@/services/shared/utils';
export default defineComponent({
  name: 'AppBreadcrumbBarComponent',
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _a, _b, _c;

    var _useInit = useInit(),
        base = _useInit.base;

    return {
      // Base
      breadcrumb: ((_a = base.route.meta) === null || _a === void 0 ? void 0 : _a.breadcrumb) && Array.isArray((_b = base.route.meta) === null || _b === void 0 ? void 0 : _b.breadcrumb) ? (_c = base.route.meta) === null || _c === void 0 ? void 0 : _c.breadcrumb : [] // Dữ liệu để hiển thị breadcrumb (thanh điều hướng)

    };
  }
});