export default {
  'btn_submit': 'Gửi',
  'btn_reset': 'Cài lại',
  'btn_new': 'Tạo mới',
  'btn_create': 'Tạo mới',
  'btn_update': 'Cập nhật',
  'btn_delete': 'Xóa',
  'btn_cancel': 'Hủy',
  'btn_back': 'Quay lại',
  'dynamic_form_title': 'Form động',
  'dynamic_form_subtitle': 'Dynamic form subtitle',
};
