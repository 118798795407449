// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/app-banner.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/**\n * Load global thông qua sass-loader\n * https://webdevetc.com/programming-tricks/vue3/vue3-guides/vue-3-global-scss-sass-variables/\n */\n.transition-opacity[data-v-7a25fce3] {\n  transition: opacity;\n}\n.duration-100[data-v-7a25fce3] {\n  transition-duration: 0.1s;\n}\n.opacity-0[data-v-7a25fce3] {\n  opacity: 0;\n}\n.opacity-100[data-v-7a25fce3] {\n  opacity: 100;\n}\n.app-aside-advertisement[data-v-7a25fce3] {\n  cursor: pointer;\n  width: 100%;\n  max-height: 600px;\n  padding-top: 200%;\n  /* 600px / 300px Aspect Ratio (divide 600 by 300 = 2.00) */\n  border-radius: 10px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}", ""]);
// Exports
module.exports = exports;
