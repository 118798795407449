import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4e61f403"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "app__breadcrumb-bar-component"
};
var _hoisted_2 = {
  class: "app__breadcrumb-bar-component__breadcrumb"
};
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumb, function (item, index) {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: index
    }, [_createVNode(_component_router_link, {
      to: {
        name: item.routeName,
        params: item.params
      }
    }, {
      default: _withCtx(function () {
        return [_createTextVNode(_toDisplayString(item.text), 1
        /* TEXT */
        )];
      }),
      _: 2
      /* DYNAMIC */

    }, 1032
    /* PROPS, DYNAMIC_SLOTS */
    , ["to"]), index !== _ctx.breadcrumb.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_3, " / ")) : _createCommentVNode("v-if", true)], 64
    /* STABLE_FRAGMENT */
    );
  }), 128
  /* KEYED_FRAGMENT */
  ))])]);
}