import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/default_avatar.svg';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1ae48e03"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "user-avatar-component"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
var _hoisted_5 = ["src"];
var _hoisted_6 = {
  key: 4,
  src: _imports_0,
  class: "user-avatar-component__avatar"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.data.imgSrc ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.data.imgSrc,
    class: "user-avatar-component__avatar"
  }, null, 8
  /* PROPS */
  , _hoisted_2)) : _ctx.data.currentUser && _ctx.data.currentUser.avatarUrl ? (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _ctx.data.currentUser.avatarUrl,
    class: "user-avatar-component__avatar"
  }, null, 8
  /* PROPS */
  , _hoisted_3)) : _ctx.data.currentUser && _ctx.data.currentUser.avatarThumbnailUrl ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    src: _ctx.data.currentUser.avatarThumbnailUrl,
    class: "user-avatar-component__avatar"
  }, null, 8
  /* PROPS */
  , _hoisted_4)) : _ctx.data.currentUser && _ctx.data.currentUser.avatar ? (_openBlock(), _createElementBlock("img", {
    key: 3,
    src: _ctx.data.currentUser.avatar,
    class: "user-avatar-component__avatar"
  }, null, 8
  /* PROPS */
  , _hoisted_5)) : (_openBlock(), _createElementBlock("img", _hoisted_6))]);
}