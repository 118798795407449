// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/feature/auth/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/**\n * Load global thông qua sass-loader\n * https://webdevetc.com/programming-tricks/vue3/vue3-guides/vue-3-global-scss-sass-variables/\n */\n.transition-opacity[data-v-018137b7] {\n  transition: opacity;\n}\n.duration-100[data-v-018137b7] {\n  transition-duration: 0.1s;\n}\n.opacity-0[data-v-018137b7] {\n  opacity: 0;\n}\n.opacity-100[data-v-018137b7] {\n  opacity: 100;\n}\n.auth-layout[data-v-018137b7] {\n  height: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: row;\n}\n.auth-layout__section-left[data-v-018137b7] {\n  flex: 1;\n  background: linear-gradient(180deg, #dd0000 0%, #840000 100%);\n  position: relative;\n}\n.auth-layout__section-left__background[data-v-018137b7] {\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  width: 100%;\n  height: 100%;\n  mix-blend-mode: overlay;\n  background-size: cover;\n}\n.auth-layout__section-left > img[data-v-018137b7] {\n  position: absolute;\n  top: 135px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n.auth-layout__section-right[data-v-018137b7] {\n  flex: 2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}", ""]);
// Exports
module.exports = exports;
