import { defineComponent, reactive } from 'vue';
export default defineComponent({
  name: 'AuthLayout',
  setup: function setup() {
    var data = reactive({}); // Life circle

    return {
      data: data
    };
  }
});