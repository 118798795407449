import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent, ref } from 'vue';
import { Dialog, DialogTitle, DialogDescription } from '@headlessui/vue'; // Định nghĩa data type của component [props] bằng Typescript

var AppDialogComponentProps = function AppDialogComponentProps() {
  _classCallCheck(this, AppDialogComponentProps);
};

export default defineComponent({
  name: 'AppDialogComponent',
  components: {
    Dialog: Dialog,
    DialogTitle: DialogTitle,
    DialogDescription: DialogDescription
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    content: {
      type: String,
      default: '',
      required: true
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var completeButtonRef = ref(null);

    var onClickPrimary = function onClickPrimary() {
      return emit('onClickPrimary');
    };

    var onClickSecondary = function onClickSecondary() {
      return emit('onClickSecondary');
    };

    var onClose = function onClose() {
      return emit('onClose');
    };

    return {
      completeButtonRef: completeButtonRef,
      onClickPrimary: onClickPrimary,
      onClickSecondary: onClickSecondary,
      onClose: onClose
    };
  }
});