import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import { defineComponent } from 'vue'; // Định nghĩa data type của component [props] bằng Typescript

var AppEmptyComponentProps = function AppEmptyComponentProps() {
  _classCallCheck(this, AppEmptyComponentProps);
};

export default defineComponent({
  name: 'AppEmptyComponent',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    subtitle: {
      type: String,
      default: null,
      required: false
    },
    action: {
      type: String,
      default: null,
      required: false
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var onClick = function onClick() {
      return emit('onAction');
    };

    return {
      onClick: onClick
    };
  }
});