import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "app",
  "data-view": ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_AppHomeDialog = _resolveComponent("AppHomeDialog");

  var _component_router_view = _resolveComponent("router-view");

  var _component_AppRouteCallDialog = _resolveComponent("AppRouteCallDialog");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.dialogAppVisible ? (_openBlock(), _createBlock(_component_AppHomeDialog, {
    key: 0,
    visible: _ctx.dialogAppVisible,
    onGoToApp: _ctx.goToApp
  }, null, 8
  /* PROPS */
  , ["visible", "onGoToApp"])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createCommentVNode(" Dynamic layout load "), (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout || 'div'), {
    class: "app-container"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_router_view, {
        key: _ctx.$route.path
      })];
    }),
    _: 1
    /* STABLE */

  }))], 2112
  /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */
  )), _createVNode(_component_AppRouteCallDialog)]);
}