import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AppAsideAdvertisement',
  emits: ['onAction'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var onClick = function onClick() {
      return emit('onAction');
    };

    return {
      onClick: onClick
    };
  }
});